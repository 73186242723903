<template>
  <div class="row">
    <div class="order-2 order-md-1 col-md-6 col-lg-6 pl-lg-5 pt-lg-5 pb-lg-5 pr-lg-0 p-xl-5">
      <!--<div class="row">
        <div class="col-md-12 col-lg-5 image-section">
          <img :src="logo ? logo : require('@/assets/eh-logo.png')" alt="" />
        </div>
        <div class="col-md-12 col-lg-7 image-section d-flex">
          <div class="image-upload-container"
            ><p
              >Für optimale Integration in Ihr Unternehmensprofil empfehlen wir ein PNG-Bild mit transparentem
              Hintergrund.</p
            >
            <label class="logo-upload">
              Logo hochladen
              <input class type="file" accept="image/*" @change="loadLogo" />
            </label>
            <span v-if="$v.logo.$error && !$v.logo.required" class="error">Bitte laden Sie Ihr Logo hoch.</span></div
          >
        </div>
      </div>-->
      <h1 class="text-center d-none d-md-none">Jetzt kostenlos registrieren!</h1>
      <div class="row company-and-website-row justify-content-center">
        <div class="col-md-12 col-lg-10 company-name-input">
          <label>
            <div class="name-label-text">Firmenname:</div>
            <input
              id="company-name"
              v-model.trim="name"
              type="text"
              :class="{ 'has-error': $v.name.$error }"
              placeholder="Ihr Firmenname"
              @input="$v.name.$touch()"
            />
          </label>
          <span v-if="$v.name.$error && !$v.name.required" class="error">Bitte tragen Sie Ihren Firmennamen ein.</span>
        </div>
      </div>
      <div class="row justify-content-center mt-0 mt-md-3" :class="{ deactivated: isWebsiteFieldDeactivated }">
        <div class="col-md-12 col-lg-10">
          <label class="website-label">
            <div class="website-label-text">Website:</div>
            <input
              id="website"
              v-model.trim="website"
              class="website-input"
              type="text"
              :class="{ 'has-error': $v.website.$error }"
              placeholder="https://example.com"
              @input="$v.website.$touch()"
            />
          </label>
          <span v-if="$v.website.$error && !$v.website.required" class="error">Bitte tragen Sie Ihre URL ein.</span>
        </div>
      </div>
      <div class="row justify-content-center mt-0 mt-md-5" :class="{ deactivated: isLocationFieldDeactivated }">
        <div class="col-md-12 col-lg-10">
          <div class="location-section">
            <p class="d-none d-md-inline">
              Unsere Plattform ist regional basiert, weil ein neuer Job nicht immer am anderen Ende von Deutschland oder
              der Welt liegen muss. Tragen Sie hier den Standort Ihres Unternehmens ein, für den sie werben möchten.
            </p>
            <VueGooglePlaces
              api-key="AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY"
              types="address"
              @placechanged="onPlaceChanged"
              @noresult="onPlaceNotFound"
            >
              <input id="location" type="text" placeholder="Adresse des Standorts" @change="onManualChange" />
            </VueGooglePlaces>
            <span v-if="$v.location.$error && !$v.location.required" class="error"
              >Bitte tragen Sie Ihren Standort ein.</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 order-1 order-md-2 mb-5 text-center">
      <div class="video-wrapper mt-md-5 mb-4" :class="{ 'show-video': showVideo }">
        <div ref="youtubeWrapper" class="embed-container">
          <youtube ref="youtube" video-id="XmbcNMIRKPg"></youtube>
        </div>
      </div>
      <h1 class="text-center d-block">Jetzt kostenlos registrieren!</h1>
      <a
        v-if="!showVideo"
        ref="playVideoButton"
        class="play-button mt-md-0 mt-4 d-inline-block d-md-none"
        @click="playVideo"
        ><span class="fa fa-play-circle"></span> Was ist EntwicklerHeld?</a
      >
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: {},
  data() {
    return {
      name: "",
      website: "",
      location: {},
      showVideo: false,
    };
  },
  validations: {
    name: {
      required,
    },
    website: {
      required,
    },
    location: {
      required,
    },
    form: ["name", "website", "location"],
  },
  computed: {
    isWebsiteFieldDeactivated() {
      return this.name === "";
    },
    isLocationFieldDeactivated() {
      return this.website === "";
    },
    googleMapsLatitude() {
      if (typeof this.location.latitude !== "undefined") {
        return this.location.latitude;
      }

      return 51.0;
    },
    googleMapsLongitude() {
      if (typeof this.location.longitude !== "undefined") {
        return this.location.longitude;
      }

      return 10.2123631;
    },
    zoomLevel() {
      if (typeof this.location.longitude !== "undefined") {
        return 13;
      }

      return 6;
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    onManualChange: function(event) {
      // we have to use this @change handler, because :v-model is not working in VueGooglePlaces input
      // we have to reset the location here as the VueGooglePlaces will fill the data if it founds the place
      this.setLocation({});
    },
    setLocation: function(value) {
      this.location = value;
      this.$v.location.$touch();
    },
    onPlaceChanged: function(data) {
      this.setLocation({
        address: data.street_number ? data.route + " " + data.street_number : data.route,
        zipCode: data.postal_code,
        city: data.locality,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
      });
    },
    onPlaceNotFound: function(data) {
      this.setLocation({});
    },
    validate() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
    playVideo() {
      this.player.playVideo();
      this.showVideo = true;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: #797979;
  font-size: 0.9rem;
}

input,
textarea {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
  border: 0;
  border-radius: 10px;
  padding: 1rem 1rem;
}

.row {
  transition: opacity 0.2s ease-in;
}

.deactivated {
  opacity: 0.3;
}

@media (max-width: 576px) {
  .deactivated {
    opacity: 1;
  }
}

.left-section {
  width: 57%;
}

.right-section {
  width: 37%;
}

.image-section {
  img {
    width: 100%;
    object-fit: cover;
  }

  .upload {
    position: relative;
    overflow: hidden;
    display: inline-block;

    /* Fancy button style 😎 */
    background-color: $eh-green;
    text-align: center;
    color: white;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  .upload input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  p {
    color: #a8a8a8;
  }
}

.name-section {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .input-field {
    width: 48%;

    input {
      width: 100%;
    }
  }
}

.teaser-section {
  margin-top: 3rem;

  textarea {
    width: 100%;
    resize: none;
  }
}

.location-section {
  input {
    width: 100%;
  }
}

.tech-section {
  margin-top: 3rem;

  .add-tech {
    display: flex;
    width: 100%;

    input {
      flex-grow: 1;
      margin-right: 1rem;
    }

    button {
      background-color: $eh-green;
      border: none;
      border-radius: 10px;
      color: white;
      box-sizing: border-box;
      padding: 0 1.4rem;
    }
  }

  ul {
    margin-top: 2rem;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 35%;
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    margin-left: 1rem;

    .description {
      margin-right: 2rem;
    }

    .delete {
      color: crimson;
      font-size: 1.6rem;

      &:hover {
        cursor: pointer;
      }
    }

    .active-color {
      color: black;
    }

    .example-color {
      color: #a8a8a8;
    }
  }
}

.error {
  display: block;
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.has-error {
  border: 1px solid #e74c3c;
}

.check {
  color: $eh-green;
  margin-right: 0.5rem;
}

.disabled-button {
  opacity: 0.5;
}

.company-name-input label,
input {
  width: 100%;
}

.website-input,
.website-label {
  width: 100%;
}

.company-and-website-row {
  margin-top: 0rem;
}

.image-upload-container {
  margin-top: auto;
}

.gradient {
  top: 0;
  position: absolute;
  height: 100%;
  width: calc(100% - 30px);
  z-index: 2;
  opacity: 0.44;
  backdrop-filter: brightness(20%);
  -webkit-backface-visibility: hidden;
  background-color: #52d3aa;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzZjk1ZWEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzUyZDNhYSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image: -webkit-gradient(linear, 0% 0%, 100% 100%, color-stop(0, #3f95ea), color-stop(1, #52d3aa));
  background-image: -webkit-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
  background-image: repeating-linear-gradient(to bottom right, #3f95ea 0%, #52d3aa 100%);
  background-image: -ms-repeating-linear-gradient(top left, #3f95ea 0%, #52d3aa 100%);
}

@media (max-width: 1200px) {
  .image-upload-container {
    padding-top: 1rem;
  }
  .company-name-input {
    margin-bottom: 1.5rem;
  }

  .location-section {
    margin-top: 1.5rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.2rem;
  }

  .company-name-input {
    margin-bottom: 1rem;
  }
  .location-section {
    margin-top: 1.5rem;
  }

  .play-button {
    font-size: 1.2rem;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
  overflow: hidden;
}

.video-wrapper {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .video-wrapper {
    width: 100%;
  }

  .embed-container {
    position: absolute;
    left: -2000px;
    width: 100%;
  }
  .show-video .embed-container {
    position: relative;
    left: 0;
    width: 100%;
  }
}
</style>
<style>
.cls-1 {
  fill: none;
}

.cls-2 {
  clip-path: url(#clip-path);
}

.cls-3 {
  clip-path: url(#clip-path-2);
}

.cls-4 {
  fill: #fff;
}

.cls-7 {
  fill: #3e93e5;
}

.cls-5 {
  opacity: 0.4;
}

.cls-6 {
  clip-path: url(#clip-path-3);
}

.website-label-text,
.name-label-text {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
