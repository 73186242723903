<template>
  <div class="success-wrapper">
    <img src="@/assets/party.svg" alt="" />
    <div>
      <h2>Glückwunsch, Sie haben sich erfolgreich registriert!</h2>
      <p>
        Im Anschluss können Sie bereits ihre Profilinformationen vervollständigen, damit wir die Freischaltung Ihres
        Profils schnellstmöglich durchführen können.
      </p>
      <p class="contact">
        Sollten sie Fragen haben <strong>kontaktieren</strong> Sie uns einfach:
        <br />
        <a href="mailto:sales@entwicklerheld.de">sales@entwicklerheld.de</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  div {
    text-align: center;
    width: 80%;
  }
}
</style>
