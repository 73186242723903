<template>
  <div id="registration-form" class="row justify-content-center wrapper">
    <div class="registration-box col-md-12 col-lg-12 col-xl-8">
      <form-wizard
        ref="registrationWizard"
        title=""
        subtitle=""
        next-button-text="Weiter"
        back-button-text="Zurück"
        finish-button-text="Registrierung abschließen"
        step-size="xs"
        color="#fff"
        error-color="#fff"
      >
        <template slot="step" scope="props">
          <wizard-step
            :tab="props.tab"
            :step-size="props.stepSize"
            :transition="props.transition"
            :index="props.index"
            @click.native="delegate(props)"
            @keyup.enter.native="props.navigateToTab(props.index)"
          >
          </wizard-step>
        </template>
        <tab-content title="Allgemeine Daten" :before-change="() => validateStep('general')">
          <div class="tab-wrapper">
            <base-error v-if="registerCompanyRequest.error" class="apiError">
              Da ist etwas schief gelaufen. Versuche es noch einmal oder schreibe uns eine E-Mail an
              <a href="mailto:hallo@entwicklerheld.de">hallo@entwicklerheld.de</a>
            </base-error>
            <general-form ref="general" @on-validate="mergePartialModels" />
          </div>
        </tab-content>
        <tab-content title="Verifizierung" :before-change="onComplete">
          <div class="tab-wrapper">
            <base-error v-if="registerCompanyRequest.error" class="apiError">
              Da ist etwas schief gelaufen. Versuche es noch einmal oder schreibe uns eine E-Mail an
              <a href="mailto:hallo@entwicklerheld.de">hallo@entwicklerheld.de</a>
            </base-error>
            <verification-form ref="verification" />
          </div>
        </tab-content>
        <tab-content title="Glückwunsch">
          <div class="tab-wrapper">
            <success-screen />
          </div>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-right">
            <wizard-button
              v-if="props.activeTabIndex === 0"
              class="wizard-footer-right cta-button"
              @click.native="props.nextTab()"
              >Weiter
            </wizard-button>

            <wizard-button
              v-if="props.activeTabIndex === 1"
              class="wizard-footer-right cta-button finish-button"
              @click.native="onComplete()"
            >
              <span v-if="!registerCompanyRequest.loaded" class="fa fa-spinner" />
              <span>Registrieren & Daten speichern</span>
            </wizard-button>

            <wizard-button
              v-if="props.activeTabIndex === 2"
              class="wizard-footer-right cta-button finish-button"
              @click.native="routeToProfile()"
            >
              <span>Daten vervollständigen</span>
            </wizard-button>
          </div>
          <div class="wizard-footer-left">
            <wizard-button
              v-if="props.activeTabIndex === 1"
              class="cta-button back-button"
              @click.native="props.prevTab()"
            >
              Zurück
            </wizard-button>
          </div>
        </template>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton, WizardStep } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import GeneralForm from "@/components/register/PGeneralForm.vue";
import VerificationForm from "@/components/register/PVerificationForm.vue";
import SuccessScreen from "@/components/register/PSuccessScreen.vue";
import { services } from "@/main";
import BaseError from "@/components/base/BaseError";
import { eventBus } from "@/eventbus";
import { navigateTo } from "@/utils/routing";
export default {
  components: {
    BaseError,
    FormWizard,
    TabContent,
    WizardStep,
    WizardButton,
    GeneralForm,
    VerificationForm,
    SuccessScreen,
  },
  props: {},
  data() {
    return {
      registerCompanyRequest: { loaded: true, error: null },
      finalModel: { techStack: [] },
      isCompleted: false,
    };
  },
  computed: {},
  watch: {
    "registerCompanyRequest.loaded": function() {
      if (!this.registerCompanyRequest.loaded || this.registerCompanyRequest.error) {
        return;
      }
      this.$refs.registrationWizard.changeTab(0, 2);
    },
  },
  mounted() {
    document.cookie = "CompanyInformation=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    eventBus.$emit("reloadProfileInformation");

    eventBus.$on("userLoggedIn", async () => {
      let result = null;
      await services.companyService.getEmployeeByUserIdPromise().then(function(serviceResult) {
        result = serviceResult;
      });

      if (!result.error && result.apiData.isRegistered) {
        navigateTo(this.$router, { name: "profile" });
        return;
      }

      //TODO Better to throw an event on close of auth0 modal and react here
      if (this.isCompleted) {
        this.onComplete();
      }
    });
  },
  methods: {
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    onComplete: function() {
      const verificationResult = this.$refs.verification.validate();
      const model = verificationResult.model;
      services.googleAnalyticsService.sendEvent(
        "Registration",
        "submit-complete-step",
        verificationResult.isValid + "#" + model.email + "#" + model.phone + "#" + model.checked
      );
      if (verificationResult.isValid) {
        this.isCompleted = true;
        if (!services.authenticationService.isAuthenticated()) {
          services.authenticationService.signUp();
          document.getElementsByClassName("auth0-lock-close-button")[0].remove();
          return;
        }
        this.finalModel = Object.assign({}, this.finalModel, verificationResult.model);
        this.registerCompanyRequest = services.companyService.registerCompany(this.finalModel);
      }
    },
    delegate: function(props) {
      if (this.isCompleted) {
        return function() {};
      }
      return props.navigateToTab(props.index);
    },
    mergePartialModels(model, isValid) {
      services.googleAnalyticsService.sendEvent(
        "Registration",
        "submit-first-step",
        isValid + "#" + model.name + "#" + model.website + "#" + model.location.address
      );
      if (isValid) {
        this.finalModel = Object.assign({}, this.finalModel, model);
      }
    },
    routeToProfile() {
      navigateTo(this.$router, { name: "profile" });
      eventBus.$emit("userLoggedIn");
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  background-color: white;
  box-shadow: $elevation3;
  margin-top: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  overflow: hidden;
}

@media (max-width: 992px) {
  .tab-wrapper {
    padding: 1.2rem;
  }
}

.cta-button {
  margin-top: 1rem;
  background-color: $eh-green;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0.8rem 1rem;
  border-radius: 10px;
}

@media (max-width: 769px) {
  .tab-wrapper {
    margin-top: 0.5rem;
    box-shadow: none;
    border-radius: 0;
  }
}

@media (max-width: 769px) {
  .cta-button {
    border-radius: 0;
    width: 100%;
  }

  .back-button {
    background: none;
    color: black;
  }
}

@media (max-width: 769px) {
  .vue-form-wizard .wizard-card-footer .wizard-footer-right {
    width: 100%;
    text-align: center;
  }

  .vue-form-wizard .wizard-card-footer .wizard-footer-left {
    width: 100%;
    text-align: center;
  }
}

.apiError {
  margin: 3rem 3rem 1rem;
}

.registration-box {
  border-radius: 1rem;
  max-width: 1150px;
}
</style>
<style>
#registration-form .active .wizard-icon {
  color: #4fadd8;
}

#registration-form {
  background: linear-gradient(0deg, transparent, #f5f4f7, #f5f4f7);
  /* This is to make the gray background fit with the paddings of the parent .wrapper.container-fluid*/
  width: calc(100% + 30px);
}
#registration-form .wizard-progress-with-circle {
  background-color: rgba(255, 255, 255, 0.2);
}
.stepTitle {
  margin-top: 0.5rem;
}

@media (max-width: 769px) {
  .stepTitle {
    font-size: 0.8rem;
  }

  #registration-form {
    background: white;
  }

  #registration-form .wizard-progress-with-circle {
    display: none;
  }

  #registration-form .wizard-nav.wizard-nav-pills {
    display: none;
  }

  #registration-form .wizard-header {
    display: none;
  }
}
</style>
