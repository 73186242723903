<template>
  <div class="verification-form-div">
    <p class="d-none d-md-inline">
      Um Sie zu verifizieren, benötigen wir Ihre Telefonnummer. Sie können im weiteren Verlauf ihr Firmenprofil
      erstellen und bearbeiten und die Funktionen von EntwicklerHeld unverbindlich testen. Sobald das Firmenprofil für
      die Veröffentlichung bereit ist, wird Sie einer unserer Mitarbeiter kontaktieren. Nach erfolgreicher
      <strong>Verifizierung</strong> wird Ihr Firmenprofil umgehend <strong>freigeschaltet</strong>.<br />Ihre Angaben
      werden ausschließlich zum Zweck der Registrierung, der damit verbundenen Kontaktaufnahme und technischen
      Administration verwendet. Eine Weitergabe an Dritte findet nicht statt.
    </p>
    <h1 class="d-inline d-md-none">Kontaktdaten</h1>
    <div class="input-wrapper row">
      <div class="input-field col-lg-12 col-xl-6">
        <input
          id="phone"
          v-model.trim="phone"
          type="text"
          placeholder="Telefonnummer"
          :class="{ 'has-error': $v.phone.$error }"
        />
        <span v-if="$v.phone.$error && !$v.phone.required" class="error">Bitte tragen Sie Ihre Telefonnummer ein.</span>
      </div>
      <div class="input-field col-lg-12 col-xl-6">
        <input
          id="email"
          v-model.trim="email"
          type="text"
          placeholder="E-Mail Adresse"
          :class="{ 'has-error': $v.email.$error }"
        />
        <span v-if="$v.email.$error && !$v.email.required" class="error"
          >Bitte tragen Sie Ihre E-Mail-Adresse ein.</span
        >
        <span v-if="$v.email.$error && !$v.email.email" class="error"
          >Bitte tragen Sie eine gültige E-Mail-Adresse ein.</span
        >
      </div>
    </div>
    <div class="data-security row">
      <div class="col">
        <div class="form-check">
          <input id="dataSecurityCheckbox" v-model="checked" class="form-check-input" type="checkbox" />
          <label class="form-check-label" for="dataSecurityCheckbox"
            >Ich stimme den Bedingungen zu, die in der
            <a href="https://entwicklerheld.de/data_privacy/" target="_blank">Datenschutzerklärung</a> dargelegt
            sind.</label
          >
          <span v-if="$v.checked.$error && !$v.checked.required" class="error"
            >Bitte nehmen Sie die Datenschutzerklärung zur Kenntnis.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  props: {},
  data() {
    return {
      phone: "",
      email: "",
      checked: "",
    };
  },
  validations: {
    phone: {
      required,
    },
    email: {
      required,
      email,
    },
    checked: { sameAs: sameAs(() => true) },
    form: ["phone", "email", "checked"],
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return { isValid, model: this.$data };
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 993px) {
  .verification-form-div {
    padding: 3rem;
  }
}

.input-wrapper {
  margin-top: 2rem;

  input {
    width: 100%;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
    padding: 1rem 1rem;
  }

  .has-error {
    border: 1px solid #e74c3c;
  }
}

.data-security {
  margin-top: 1rem;

  label {
    padding-left: 5px;
  }

  label a {
    color: $eh-green;
  }

  .form-check-input {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    padding: 10px;

    @media (max-width: 576px) {
      -ms-transform: none;
      -moz-transform: none;
      -webkit-transform: none;
      -o-transform: none;
    }
  }
}

.error {
  display: block;
  color: #c0392b;
  font-size: 0.8rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

@media (max-width: 1200px) {
  .input-field {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 1.2rem;
  }
}
</style>
